import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { slide as Menu } from "react-burger-menu";
import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { SCREENS } from '../responsive'
import { styles } from "./menuStyles";
import { NavLink } from 'react-router-dom';

const ListContainer = styled.ul`
 ${tw`
    flex
    list-none
 `}
`;

const NavItem = styled.li`
    ${tw`
        flex
        items-center
        text-sm
        md:text-base
        font-light
        mr-1
        md:mr-5
        cursor-pointer
        transition
        duration-300
        ease-in-out
        hover:text-red1
        text-white
    `}

    ${({ menu }) =>
        menu &&
        css`
        ${tw`
            text-white
            text-xl
            mb-3
            focus:text-red1
        `};
    `};
`;

const NavItems = () => {
    const isMobile = useMediaQuery({ maxWidth: SCREENS.md });

    if (isMobile)
        return (
            <Menu right styles={styles}>
                <ListContainer>
                    <NavItem menu>
                        <NavLink exact to="/" activeClassName="text-red1">Home</NavLink>
                    </NavItem>
                    <NavItem menu>
                        <NavLink to="/story/create" activeClassName="text-red1">Mulai Nulis</NavLink>
                    </NavItem>
                    <NavItem menu>
                        <NavLink to="/tutorial" activeClassName="text-red1">Tutorial</NavLink>
                    </NavItem>
                    <NavItem menu>
                        <NavLink to="/account" activeClassName="text-red1">My Account</NavLink>
                    </NavItem>
                </ListContainer>
            </Menu>
        );

    return (
        <ListContainer>
            <NavItem active>
                <NavLink exact to="/" activeClassName="text-red1">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/story/create" activeClassName="text-red1">Mulai Nulis</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/tutorial" activeClassName="text-red1">Tutorial</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/account" activeClassName="text-red1">My Account</NavLink>
            </NavItem>
        </ListContainer>
    );
}

export default NavItems
