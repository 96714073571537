import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';
import { Youtube, Twitter, Instagram } from '../../../assets/Icon';

const Base = styled.div`
    border-top: 1px solid #151515;
    min-height: 70px;
    ${tw`
        flex
        flex-row
        items-center
        mt-auto
    `}
`;

const Container = styled.footer`
    ${tw`
        container
        lg:mx-auto
        mx-4
        flex
        flex-row
        justify-between
    `}
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const WebTitle = styled.p`
    ${tw`
        flex
        items-center
        text-sm
        text-white
    `}
`;

const SocialContainer = styled.span`
    ${tw`
        flex
        flex-row
        justify-between
    `}
`;

const SocialIcon = styled.span`
    width: 34px;
    height: 34px;
    background-color: #151515;
    ${tw`
        flex
        items-center
        justify-center
        rounded-full
        mx-1
    `}
`;

const Footer = () => {
    return (
        <Base>
            <Container>
                <WebTitle>
                    Horroku.com &copy; 2021
                </WebTitle>
                <SocialContainer>
                    <SocialIcon>
                        <img src={Twitter} alt="" />
                    </SocialIcon>
                    <SocialIcon>
                        <img src={Youtube} alt="" />
                    </SocialIcon>
                    <SocialIcon>
                        <img src={Instagram} alt="" />
                    </SocialIcon>
                </SocialContainer>
            </Container>
        </Base>
    )
}

export default Footer
