import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Logo from '../elements/Logo';
import NavItems from './navItems';
import { Search } from '../../../assets/Icon'
import { Link } from 'react-router-dom';

const Base = styled.div`
    min-height: 70px;
    border-bottom: 1px solid #282828;
    ${tw`
        flex
        flex-row
        w-full
    `}
`;

const Container = styled.div`
    ${tw`
        container 
        lg:mx-auto
        mx-4
        flex
        flex-row
        items-center
    `}
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const LogoContainer = styled.div`
    ${tw`
        md:mr-8
        -mr-6
        md:ml-0
        ml-auto
    `}
`;

const SearchButton = styled.div`
    background-color: #151515;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 100px;
    &:hover {
        background-color: #282828;
    }
`;

const Navbar = () => {
    return (
        <Base>
            <Container>
                <LogoContainer>
                    <Link to="/">
                        <Logo />
                    </Link>
                </LogoContainer>
                <NavItems />
                <Link className="ml-auto" to="/search">
                    <SearchButton>
                        <img src={Search} alt="" />
                    </SearchButton>
                </Link>
            </Container>
        </Base>
    )
}

export default Navbar
