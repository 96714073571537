import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';
import Card from '../../components/Card';

const Container = styled.div`
    ${tw`
        grid 
        grid-cols-6
        md:grid-cols-4 
        lg:grid-cols-6
        md:gap-8 
        gap-40 
        md:overflow-x-hidden
        overflow-x-scroll
        mt-7
        px-4
        md:px-0
    `}
`;

const HotThread = () => {
    return (
        <Container>
            <Card type="Thread" />
            <Card type="Thread" />
            <Card type="Thread" />
            <Card type="Thread" />
            <Card type="Thread" />
            <Card type="Thread" />
        </Container>
    )
}

export default HotThread
