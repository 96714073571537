import React from 'react'
// import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import tw from 'twin.macro';
import styled from 'styled-components';

const ButtonPrimary = styled.button`
    background-color: #FF002E;
    box-shadow: 0px 4px 15px 0px rgba(255,0,46,0.7);
    -webkit-box-shadow: 0px 4px 15px 0px rgba(255,0,46,0.7);
    -moz-box-shadow: 0px 4px 15px 0px rgba(255,0,46,0.7);
    &:hover {
        background-color: #FF7E96;
    }
    ${tw`
        flex 
        flex-row 
        px-10 
        py-2
        font-normal 
        text-center
        text-sm
        mx-auto
        mt-10
        capitalize 
        rounded 
        focus:outline-none 
        cursor-pointer
        text-white
        mb-10
    `}
`;

export default function Button(props) {
    if (props.type === 'button') {
        return (
            <ButtonPrimary>
                {props.title}
            </ButtonPrimary>
        )
    }
}

Button.propTypes = {
    type: propTypes.oneOf(["button", "link", "dropdown"]),
    onClick: propTypes.func,
    target: propTypes.string,
    href: propTypes.string,
    className: propTypes.string,
    isDisabled: propTypes.bool,
    isLoading: propTypes.bool,
    isSmall: propTypes.bool,
    isLarge: propTypes.bool,
    isDanger: propTypes.bool,
    isPrimary: propTypes.bool,
    isBlock: propTypes.bool,
    isExternal: propTypes.bool,
    hasShadow: propTypes.bool,
    hasBorder: propTypes.bool,
    title: propTypes.string
}