export const styles = {
    bmBurgerButton: {
        position: "absolute",
        width: "18px",
        height: "18px",
        left: "20px",
        top: "24px",
    },
    bmBurgerBars: {
        background: "#FFFFFF",
        borderRadius: 1,
        height: 2
    },
    bmBurgerBarsHover: {
        background: "#FF002E",
    },
    bmCrossButton: {
        marginTop: 10,
        marginRight: 10,
        height: "32px",
        width: "32px",
    },
    bmCross: {
        background: "#FFFFFF",
    },
    bmMenuWrap: {
        position: "fixed",
        width: "60%",
        height: "100%",
        top: "0px",
    },
    bmMenu: {
        background: "#090909",
        padding: "2.5em 1.5em 0",
        fontSize: "1.15em",
    },
    bmMorphShape: {
        fill: "#FF002E",
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em",
    },
    bmItem: {
        display: "inline-block",
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
    },
};