import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';
import Card from '../../components/Card';

const Container = styled.div`
    ${tw`
        md:mx-0
        mx-4
        grid 
        grid-cols-2 
        md:grid-cols-3 
        lg:grid-cols-4 
        gap-8 
        mt-7
        items-center
    `}
`;

const TopStoryteller = () => {
    return (
        <Container>
            <Card type="User" />
            <Card type="User" />
            <Card type="User" />
            <Card type="User" />
            <Card type="User" />
            <Card type="User" />
            <Card type="User" />
            <Card type="User" />
        </Container>
    )
}

export default TopStoryteller
