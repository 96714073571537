import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Home } from './app/pages/Home';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Search from './app/pages/Search';

const AppContainer = styled.div`
 ${tw`
    flex
    flex-col
    w-full
    h-full
    font-poppins
 `}
`

function App() {
  return (
    <AppContainer>
      <Router>
        <Route path="/" component={Home} exact />
        <Route path="/search" component={Search} />
      </Router>
    </AppContainer>
  );
}

export default App;
