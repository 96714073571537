import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';
import Card from '../../components/Card';
import Button from '../../components/elements/Button';

const Container = styled.div`
    ${tw`
        grid 
        grid-cols-1 
        md:grid-cols-3 
        lg:grid-cols-4 
        gap-8
        overflow-x-hidden
        md:mx-auto
        mx-8
        mt-7
    `}
`;

const Explore = () => {
    return (
        <>
            <Container>
                <Card type="Post" />
                <Card type="Post" />
                <Card type="Post" />
                <Card type="Post" />
                <Card type="Post" />
                <Card type="Post" />
                <Card type="Post" />
                <Card type="Post" />
            </Container>
            <Button type="button" title="Load More" />
        </>
    )
}

export default Explore
