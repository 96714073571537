import React from 'react'
import { logoNavbar } from '../../../../assets'

const Logo = () => {
    return (
        <div>
            <img src={logoNavbar} alt="Logo horrorku" />
        </div>
    )
}

export default Logo
