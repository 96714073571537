import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';
import { SearchAlt } from '../../../assets/Icon';
import Navbar from '../../components/Navbar';

const Container = styled.div`
    ${tw`
        container
        flex
        flex-col
        items-center
        w-full
        h-full
        md:overflow-x-hidden
        overflow-x-hidden
        md:mx-auto
        px-4
        mt-8
    `}
`;

const HeaderTitle = styled.h1`
    ${tw`
        text-white
        text-4xl
        font-semibold
        text-center
    `}
`;
const HeaderText = styled.p`
    ${tw`
        text-white
        text-center
        mt-4
    `}
`;

const SeacrhBoxWrapper = styled.div`
    ${tw`
        relative
        lg:w-1/3
        md:w-2/3
        w-full
        mt-14
    `}
`;

const SeacrhBox = styled.input`
    background-color: #101010;
    ${tw`
        outline-none
        text-white
        font-light
        py-5
        pl-5
        pr-14
        w-full
        rounded-lg
    `}
`;

const SearchIcon = styled.img`
    ${tw`
        absolute
        top-5
        right-5
    `}
`;

const Search = () => {
    return (
        <>
            <Navbar />
            <Container>
                <HeaderTitle>Get It Quick</HeaderTitle>
                <HeaderText>Temukan berbagai horror untuk <br /> menemani kalian.</HeaderText>
                <SeacrhBoxWrapper>
                    <SeacrhBox placeholder="Cari cerita horror" />
                    <button>
                        <SearchIcon src={SearchAlt} />
                    </button>
                </SeacrhBoxWrapper>
            </Container>
        </>
    )
}

export default Search
