import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro';
import Footer from '../../components/Footer';
import Hero from './hero';
import Navbar from '../../components/Navbar/index';
import Explore from './explore';
import HotThread from './hotThread';
import TopStoryteller from './topStoryteller';

const Container = styled.div`
    ${tw`
        w-full
        h-full
        md:overflow-x-hidden
        overflow-x-hidden
        container
        md:mx-auto
        md:mt-8
    `}
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const Heading = styled.h1`
    ${tw`
        mt-16
        md:mx-0
        mx-4
        text-white
        text-lg
        font-semibold
    `}
`;

export const Home = () => {
    return (
        <>
            <Navbar />
            <Container>
                <Hero />
                <Heading>Top Storyteller</Heading>
                <TopStoryteller />
                <Heading>Hot Thread (Soon)</Heading>
                <HotThread />
                <Heading>Explore</Heading>
                <Explore />
            </Container>
            <Footer />
        </>
    )
}