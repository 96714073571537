import React from 'react'
import propTypes from 'prop-types';
import tw from 'twin.macro';
import styled from 'styled-components';

const PostCardContainer = styled.div`
    ${tw`
        flex
        flex-col
        md:w-auto
        min-w-latestPost
    `}
`;

const PostCardImage = styled.img`
    border-radius: 14px;
`;

const PostCardTitle = styled.h2`
    ${tw`
       font-medium
       text-white 
       my-3.5
       line-clamp-2
    `}
`;

const PostCardInfoContainer = styled.div`
    ${tw`
        flex
        flex-row
        items-center
    `}
`;

const PostCardInfoUserImage = styled.img`
    ${tw`
        object-cover 
        w-12 
        h-12 
        mr-2 
        rounded-full
    `}
`;

const PostCardInfoUserName = styled.p`
    ${tw`
        text-sm
        text-white
        font-medium
    `}
`;

const PostCardInfoUserDate = styled.p`
    ${tw`
        text-xs
        text-white
        opacity-80
    `}
`;

const UserCardContainer = styled.div`
    ${tw`
        flex
        flex-row
        md:w-auto
        w-36
        items-center
    `}
`;

const UserCardImage = styled.img`
    ${tw`
        rounded-full
        object-cover 
        w-12 
        h-12 
        mr-2 
    `}
`;

const UserCardName = styled.p`
    ${tw`
        text-sm
        text-white
        font-medium
    `}
`;

const UserCardPostCount = styled.p`
    ${tw`
        text-xs
        text-white
        opacity-80
    `}
`;

const ThreadCardContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #151515;
    ${tw`
        flex
        flex-col
        items-center
        md:w-auto
        w-36
    `}
`;

const ThreadcardImage = styled.img`
    border-top-right-radius: 8px;    
    border-top-left-radius: 8px;    
    ${tw`
        h-3/6
    `}
`;

const ThreadcardUserImage = styled.img`
    border-radius-top-right: 8px;    
    border-radius-top-left: 8px;    
    ${tw`
        object-cover 
        w-20 
        h-20 
        mr-2 
        rounded-full
        -mt-10
    `}
`;

const ThreadcardTitle = styled.p`
    ${tw`
        font-medium
        text-sm
        text-white
        md:mt-7
        md:mb-12
        mt-3.5
        mb-6
    `}
`;

const Card = (props) => {

    if (props.type === 'Post') {
        return (
            <PostCardContainer>
                <PostCardImage src="https://cdn-image.hipwee.com/wp-content/uploads/2019/09/hipwee-abandoned-architecture-art-2486662-750x422.jpg" />
                <PostCardTitle>Aenean dignissim pulvinar ex, id venenatis ante luctus id.</PostCardTitle>
                <PostCardInfoContainer>
                    <PostCardInfoUserImage src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80" />
                    <div>
                        <PostCardInfoUserName>@yukebrillianth</PostCardInfoUserName>
                        <PostCardInfoUserDate>Baru Saja</PostCardInfoUserDate>
                    </div>
                </PostCardInfoContainer>
            </PostCardContainer>
        )
    } else if (props.type === 'User') {
        return (
            <UserCardContainer>
                <UserCardImage src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80" />
                <div>
                    <UserCardName>@mehdizadeh</UserCardName>
                    <UserCardPostCount>24 Posts</UserCardPostCount>
                </div>
            </UserCardContainer>
        )
    } else if (props.type === 'Thread') {
        <ThreadCardContainer>
            <ThreadcardImage src="https://cdn-image.hipwee.com/wp-content/uploads/2019/09/hipwee-abandoned-architecture-art-2486662-750x422.jpg" />
            <ThreadcardUserImage src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80" />
            <ThreadcardTitle>KKN Desa Penari</ThreadcardTitle>
        </ThreadCardContainer>
    }

    return (
        <ThreadCardContainer>
            <ThreadcardImage src="https://cdn-image.hipwee.com/wp-content/uploads/2019/09/hipwee-abandoned-architecture-art-2486662-750x422.jpg" />
            <ThreadcardUserImage src="https://images.unsplash.com/photo-1555952517-2e8e729e0b44?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80" />
            <ThreadcardTitle>KKN Desa Penari</ThreadcardTitle>
        </ThreadCardContainer>
    );
}

Card.propTypes = {
    type: propTypes.oneOf(['Post', 'User', 'Thread'])
}

export default Card
