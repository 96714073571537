import React from 'react'
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = styled.div`
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.50) 67.71%, rgba(0, 0, 0, 1) 100%), url("https://images.unsplash.com/photo-1594662234267-f47effc265a4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    ${tw`
        container
        flex
        flex-col
        items-center
        justify-center
        w-full
        md:h-96
        h-64
        md:rounded-lg
        md:mb-auto
        -mb-12
    `}
    @media (max-width: 768px) {
        max-width: 100%;
    }
`;

const HeroTitle = styled.h1`
    ${tw`
        md:text-7xl
        text-3xl
        text-white
        font-bold
    `}
`;

const HeroDescription = styled.p`
    ${tw`
        md:text-lg
        text-white
        text-center
        mt-5
    `}
`;

const Hero = () => {
    return (
        <Container>
            {/* <HeroImage src="https://images.unsplash.com/photo-1594662234267-f47effc265a4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80" /> */}
            <HeroTitle>New Experience</HeroTitle>
            <HeroDescription>Sharing and reading Horror stories much <br />
                easier than any before</HeroDescription>
        </Container>
    )
}

export default Hero
